import React, { FC } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';

interface ProgressProps {
  step: number;
}

const Container = styled.div`
  display: flex;
  gap: 0.5rem;
  height: 0.25rem;
  background: #fff;
  @media ${tablet} {
  }
`;

const ProgressBarContainer = styled.div<{ active: boolean }>`
  background: ${({ active }) => (active ? '#4CB944' : '#C9EAC7')};
  border-radius: 50px;
  flex-grow: 1;
  height: 100%;
  transition: background-color 0.25s;
`;

const ResultsProgress: FC<ProgressProps> = ({ step }) => {
  const currentStep = Math.min(Math.max(step, 1), 5);

  return (
    <Container>
      {[1, 2, 3, 4, 5].map(section => (
        <ProgressBarContainer key={section} active={section <= currentStep} />
      ))}
    </Container>
  );
};

export default ResultsProgress;
