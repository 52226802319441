import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { tablet } from 'styles/breakpoints';
import Header from 'components/Header';
import { PrimaryButton, Seo } from 'components';
import Chart from 'assets/icons/fat-burnning-chart-results.svg';
import Checkmark from 'assets/icons/green-checkmark.svg';
import Mark from 'assets/icons/extra-red-mark.svg';
import { useQuizData } from 'utils/hooks';
import { useRouter } from 'apis/history';
import ResultsProgress from 'components/ResultsProgress';
import Arrow from 'assets/icons/quiz/personal-summary-arrow.svg';

const ResultsFatBurning: FC = () => {
  const data = useQuizData('resultsFatBurning');
  const { goToResultsPersonalized } = useRouter();

  const [animate, setAnimate] = useState(false);
  const [leftPosition, setLeftPosition] = useState('-13px');
  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
      setLeftPosition('7%');
    }, 100); // Wait for 1 second (1000ms) after reaching 100%

    return () => clearTimeout(timer); // Cleanup timeout
  }, []);

  return (
    <>
      <Seo />
      <Outter>
        <Header onBack={() => window.history.back()} sticky={false} />
        <ResultsProgress step={2} />
      </Outter>
      <Main>
        <Container>
          <Title dangerouslySetInnerHTML={{ __html: data?.label }} />
          <Subtitle dangerouslySetInnerHTML={{ __html: data?.subtitle }} />
          <Content>
            <TopTitleContainer>
              <SectionTitle>{data?.topTitle}</SectionTitle>
            </TopTitleContainer>
            <ChartContainer2>
              <BubbleContainer animate={animate} leftPosition={leftPosition}>
                <YouText>
                  {data?.topText}
                  <Arrow />
                </YouText>
                <Bubble />
              </BubbleContainer>

              <Line2 />
              <Levels>
                {data?.levelTexts?.map((item, index) => (
                  <LevelText key={index}>{item}</LevelText>
                ))}
              </Levels>
            </ChartContainer2>
          </Content>
          <ChartContainer>
            <LeftSide>
              <LeftTitle>
                <Mark /> {data?.nowTitle}
              </LeftTitle>
              <ListBox>
                {data?.nowList.map((item: string, index: number) => (
                  <>
                    <ListItem
                      key={index}
                      dangerouslySetInnerHTML={{ __html: item }}
                    />
                    {index < data?.nowList.length - 1 && <Line />}
                  </>
                ))}
              </ListBox>
            </LeftSide>

            <RightSide>
              <RightTitle>
                <Checkmark />
                {data?.futureTitle}
              </RightTitle>
              <ListBox>
                {data?.futureList.map((item: string, index: number) => (
                  <>
                    <ListItem
                      key={index}
                      dangerouslySetInnerHTML={{ __html: item }}
                    />
                    {index < data?.futureList.length - 1 && <Line />}
                  </>
                ))}
              </ListBox>
            </RightSide>
          </ChartContainer>
          <ButtonContainer>
            <ContinueButtonStyled onClick={goToResultsPersonalized}>
              {data?.buttonTitle}
            </ContinueButtonStyled>
          </ButtonContainer>
        </Container>
      </Main>
    </>
  );
};

export default ResultsFatBurning;

const Main = styled.section`
  min-height: calc(100vh - 76px);
  background: #faf9f7;
  @media ${tablet} {
    min-height: calc(100vh - 68px);
  }
`;

const Outter = styled.section`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 3;
`;

const Container = styled.div`
  padding: 2rem 1rem 3rem;
  max-width: 30.75rem;
  margin: 0 auto;
  width: 100%;
  @media ${tablet} {
    padding: 1.5rem 1rem 6rem;
  }
`;

const Title = styled.h2`
  color: #141515;
  text-align: center;
  font-family: Inter;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  width: 100%;
  span {
    color: #f04d41;
  }
  padding-bottom: 0.5rem;
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const ChartStyled = styled(Chart)`
  width: 100%;
  height: 100%;
`;

const ButtonContainer = styled.section`
  width: 100%;
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem;
    width: 100%;
    background: #faf9f7;
  }
`;

const ContinueButtonStyled = styled(PrimaryButton)`
  margin: 1.5rem auto 0;
  @media ${tablet} {
    max-width: 28.75rem;
    margin: 0 auto;
  }
`;

const Subtitle = styled.p`
  color: #141515;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-top: 0.5rem;
  width: 100%;
  text-align: center;
  span {
    font-weight: 700;
  }
  padding-bottom: 1.5rem;
  @media ${tablet} {
  }
`;
const LeftSide = styled.section`
  width: 100%;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  border-radius: 0.75rem 0rem 0rem 0.75rem;
  border: 1px solid #f04d41;
  border-right: 0;
  background: #fde5e2;
`;

const RightSide = styled.section`
  width: 100%;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  border-radius: 0rem 0.75rem 0.75rem 0rem;
  border: 1px solid #21b66b;
  border-left: none;
  background: #d4f7e0;
`;

const LeftTitle = styled.h3`
  display: flex;
  align-items: center;
  gap: 0.375rem;
  color: #f04d41;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  @media ${tablet} {
    font-size: 1rem;
  }
`;

const RightTitle = styled(LeftTitle)`
  color: #21b66b;
`;

const ListBox = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.75rem;
  width: 100%;
`;

const ListItem = styled.p`
  color: #141515;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  @media ${tablet} {
    span {
      display: block;
    }
  }
`;

const Line = styled.section`
  height: 1px;
  width: 100%;
  background: #1415151a;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  max-width: 28.75rem;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  margin: 0 auto 1.5rem;
  border-radius: 0.75rem;
  background: #fff;
  box-shadow: 0px 6px 12px 3px rgba(29, 36, 48, 0.05);
  position: relative;
`;

const TopTitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ChartContainer = styled.section`
  display: flex;
  width: 100%;
  margin-top: 1.5rem;
  @media ${tablet} {
    margin-top: 1rem;
  }
`;

const ChartContainer2 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3.13rem;
  @media ${tablet} {
    margin-top: 2.62rem;
  }
`;

const Line2 = styled.div`
  width: 100%;
  height: 0.52006rem;
  border-radius: 625rem;
  background: linear-gradient(
    90deg,
    #fc826e 0%,
    #fbbf77 39.43%,
    #f3ed80 70.52%,
    #bff4a4 100%
  );
`;

const Levels = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
  margin: 0 1.2rem;
  @media ${tablet} {
    margin: 0 1rem;
  }
`;

const LevelText = styled.p`
  color: #727373;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const BubbleContainer = styled.div<{ animate: boolean; leftPosition: string }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.57rem;
  top: -40px;
  left: ${({ leftPosition }) => leftPosition};
  transition: left 1.5s ease-out;
  @media ${tablet} {
    top: -38px;
  }
`;

const YouText = styled.div`
  display: inline-flex;
  padding: 0.25rem 0.375rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.375rem;
  background: #727373;
  color: #fff;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  position: relative;
  text-wrap: nowrap;
  svg {
    position: absolute;
    bottom: -6px;
    path {
      fill: #727373;
    }
  }
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const Bubble = styled.div`
  width: 1rem;
  height: 1rem;
  background: #fff;
  border: 4.878px solid #727373;
  border-radius: 3rem;
`;

const SectionTitle = styled.p`
  color: #141515;
  font-family: Inter;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  padding-bottom: 0.5rem;
  @media ${tablet} {
    font-size: 1.125rem;
  }
`;
